<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb">
        <h4 style="margin:0;padding-left: 10px;">NeXa</h4>
        <span class="divider"></span>
        <span>Use Cases / Deep Research</span>
      </div>
      <div class="open_sidebar" @click="toggleSidebar">
        <span class="material-symbols-rounded icon">keyboard_double_arrow_left</span>
        <p>Open Sidebar</p>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-md-1 mt-3">
        <div class="d-flex justify-content-start cursor-pointer back" @click="goBack">
          <span class="material-symbols-rounded" style="margin: 0;">arrow_back</span>
          <span style="margin: 0 8px;">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5">
        <section class="uploads">
          <h4>Deep Research</h4>
          <form class="search-bar" role="search" @submit.prevent="submitQuery">
            <div class="input-group">
              <label for="legalQuery" class="visually-hidden">Enter your legal query or task here</label>
              <input v-model="legalQuery" type="text" id="legalQuery" class="input-field" maxlength="3000"
                v-validate="'required'" placeholder="Enter your legal query or task here"
                aria-label="Enter your legal query or task here" />
              <button type="submit" class="upload-button" tabindex="0">
                <span class="material-symbols-rounded">send</span>
              </button>
            </div>
            <b-tooltip target="tooltip_attach" triggers="hover">
              Upload any documents and let our AI show you what it can do!
            </b-tooltip>
            <footer class="search-bar-footer">
              <div class="d-flex">
                <span class="material-symbols-rounded icon-small">attach_file</span>
                <button class="attach-button" id="tooltip_attach" type="button" @click="showAttachModal">Attach</button>
                <span class="divider"></span>
                <span class="material-symbols-rounded icon-small">arrow_drop_down</span>
                <button class="country-button d-flex align-items-center" type="button" @click="toggleCountry">
                  <img v-if="selectedCountry" :src="filteredCountryImages[selectedCountry]" alt="Country Flag"
                    class="country-flag" />
                  {{ selectedCountry ? selectedCountry : "Country" }}
                </button>
              </div>
              <div>
                <span class="char-count">{{ charCount }} / 3000</span>
              </div>
            </footer>
            <div v-if="isCountry" class="country-option">
              <ul>
                <li v-for="(flag, countryCode) in filteredCountryImages" :key="countryCode"
                  @click="selectCountry(countryCode)" class="countryOption">
                  <img :src="flag" alt="Country Flag" class="country-flag" />
                  <span>{{ countryCode }}</span>
                </li>
              </ul>
            </div>
            <div v-if="files.length > 0 && files[0].length > 0" class="file-display my-4">
              <ul class="file-list">
                <li v-for="(file, index) in files[0]" :key="index"
                  class="badge d-flex align-items-center justify-content-between">
                  <span class="material-symbols-rounded badge-icon">{{ file.name.endsWith('.zip') ? 'folder' :
                    'description' }}</span>
                  <div class="file-info d-flex"
                    style="flex-grow: 1; justify-content: space-between; padding-left: 10px;">
                    <p class="badge-title mb-0 me-2">{{ file.name }}</p>
                    <p class="badge-size mb-0">{{ (file.size / (1024 * 1024)).toFixed(2) }} MB</p>
                  </div>
                  <span class="material-symbols-rounded close-icon ml-2" @click="removeFile(index)">close</span>
                </li>
              </ul>
            </div>
            <b-modal id="attachfile" hide-footer hide-header size="lg">
              <div class="d-flex justify-content-between">
                <div>
                  <h5>Upload Files</h5>
                </div>
                <div>
                  <span class="material-symbols-rounded close-icon" @click="hideAttachModal">close</span>
                </div>
              </div>
              <div>
                <FileInput style="flex-grow: 1;" :accept="['pdf']" @input-files="$set(files, 0, $event)" />
                <p class="mt-2" style="font-size:11px;font-weight: 400;">Your files will be securely stored in NeXa,
                  accessible only to you and your workspace admins, and you can delete them at any time.</p>
                <FileProcess style="width: 100%; max-height: 200px; overflow-y: auto;" :accept="['pdf']"
                  :files="files[0]" @output-files="$set(files, 1, $event)" />
              </div>
              <!-- <footer class="mt-3">
                <div class="d-flex justify-content-end">
                  <div class="upload cursor-pointer" @click="uploadFile">
                    <span class="material-symbols-rounded icon">upload</span>
                    Upload
                  </div>
                </div>
              </footer> -->
            </b-modal>
          </form>
        </section>
      </div>
      <div class="col-md-1">
      </div>
    </div>
  </div>
</template>
<script>
import countryImages from './../../../assets/country/index';
import FileInput from './../../../components/input/FileInput.vue';
import FileProcess from './../../../components/input/FileProcess.vue';
import usecase from '@/store/usecase.js';

export default {
  components: {
    FileInput, FileProcess
  },
  data() {
    return {
      loading: true,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCountry: false,
      countryImages,
      files: [[], []],
      legalQuery: '',
      selectedCountry: ''
    }
  },
  computed: {
    filteredCountryImages() {
      return Object.entries(this.countryImages).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    },
    charCount() {
      return this.legalQuery.length;
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      this.$router.push({ name: 'DeepResearchResult', params: { id: this.$route.params.id } });
    },
    toggleCountry() {
      this.isCountry = !this.isCountry;
    },
    selectCountry(countryCode) {
      this.selectedCountry = countryCode;
      this.isCountry = false;
    },
    showAttachModal() {
      this.$bvModal.show('attachfile');
    },
    hideAttachModal() {
      this.$bvModal.hide('attachfile');
    },
    uploadFile() {
      // this.$router.push({ name: 'DeepResearchResult' });
    },
    removeFile(index) {
      this.files[0].splice(index, 1);
    },
    async submitQuery() {
      try {
        this.$validator.validateAll();
        const countryMapping = {
          'AUS': 'Australia',
          'USA': 'USA',
          'CAN': 'Canada',
          'NZ': 'New Zealand',
          'SG': 'Singapore',
          'MY': 'Malaysia',
          'UK': 'United Kingdom'
        };

        const formData = new FormData();
        formData.append('language', 'en');
        formData.append('location_id', 1);
        formData.append('question', this.legalQuery);
        formData.append('type', 'deep_research');
        const jurisdiction = {};
        const country = countryMapping[this.selectedCountry];
        jurisdiction[country] = ["all"];
        formData.append('jurisdiction', JSON.stringify(jurisdiction));
        if (this.files[0] && this.files[0].length > 0) {
          this.files[0].forEach((file, index) => {
            formData.append(`question_doc[${index}]`, file);
          });
        }

        const response = await usecase.CreateUsecase(formData);
        if (response.data) {
          this.$toast.success('Successful');
          this.$router.push({ name: 'DeepResearchResult', params: { id: response.data.data.id } })
        }
      } catch (error) {
        console.error(error);
        this.$toast.error('Failed to generate deep research');
      }
    }
  }
}
</script>

<style scoped>
.page-content {
  padding: 0;
}

.file-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;

  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}

.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}

.col-md-10 {
  overflow-y: auto;
  /* Allows vertical scrolling if content exceeds the height */
  max-height: 100vh;
  /* Ensure it doesn’t exceed the viewport height */
}

.uploads {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
}

.search-bar-footer {
  background: #FAFBFC;
  border-top: 1px solid var(--Neutral-Light-Grey, #F2F3F3);
  background: var(--Neutral-Light-Grey, #F2F3F3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 0 15px;
  margin-top: -16px;
}

.search-bar {
  border-radius: 8px;
  margin-top: 24px;
  width: 100%;
}

.input-group {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background: var(--Neutral-Interface-Grey, #FAFBFC);
}

.input-field {
  flex: 1;
  background: var(--Neutral-Interface-Grey, #FAFBFC);
  color: var(--Neutral-Black, #383A3E);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  padding: 12px;
  border: none;
  outline: none;
}

.upload-button {
  border-radius: 4px;
  overflow: hidden;
  padding-top: 6px;
  margin: 8px 0px 12px;
  background: var(--Neutral-White, #FFF);
  color: #0E4485;
  box-shadow: 0px 2px 10px 0px rgba(14, 68, 133, 0.60);
}

.upload-button img {
  width: 20px;
  object-fit: contain;
}

.attach-button,
.country-button {
  color: #86888D;
  font-size: 14px;
  font-weight: 500;
}

.char-count {
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.icon-small {
  color: #86888D;
  margin: auto;
  font-size: 18px;
}

.upload {
  background: var(--primary);
  border-radius: 6px;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
}

.country-option {
  margin-left: 7vw;
  width: 100px !important;
  background: #FAFBFC;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  position: absolute;
  z-index: 2;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.country-flag {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.countryOption {
  cursor: pointer;
  margin-bottom: 10px;

  span {
    color: #86888D;
    font-size: 14px;
  }
}

.badge {
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px;
  border-radius: 8px;
  background: var(--Neutral-Interface-Grey, #FAFBFC);
  color: grey;
}

.badge-icon {
  color: var(--primary);
}

.badge-title {
  color: var(--Neutral-Black, #383A3E);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.badge-size {
  color: var(--Neutral-Dark-Grey, #86888D);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.close-icon {
  font-size: 18px;
  color: var(--status-red);
  cursor: pointer;
}
</style>